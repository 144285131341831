import { groupBy } from 'lodash';
import * as types from './types';

export default (state = {
  locationBoards: [],
  selectedLocationBoard: null,
  locationBoardsByLocationMap: {},
  isFetchingLocationBoards: false,
}, action = {}) => {
  switch (action.type) {
    case types.SET_SELECTED_LOCATION_BOARD:
      return {
        ...state,
        selectedLocationBoard: action.selectedLocationBoard,
      };
    case types.GET_LOCATION_BOARDS_REQUESTED:
      return {
        ...state,
        locationBoards: [],
        isFetchingLocationBoards: true,
      };
    case types.GET_LOCATION_BOARDS_SUCCESS:
      return {
        ...state,
        isFetchingLocationBoards: false,
        locationBoards: action.locationBoards,
        locationBoardsByLocationMap: groupBy(action.locationBoards, 'location.id'),
      };
    case types.GET_LOCATION_BOARDS_ERROR:
      return {
        ...state,
        isFetchingLocationBoards: false,
      };

    case types.ADD_LOCATION_BOARD_REQUESTED:
      return {
        ...state,
        isFetchingLocationBoards: true,
      };
    case types.ADD_LOCATION_BOARD_SUCCESS:
      return {
        ...state,
        isFetchingLocationBoards: false,
        locationBoards: [{
          ...action.newLocationBoard,
          new: true, // signal in UI to show newly added location
        }].concat(state.locationBoards),
        locationBoardsByLocationMap: groupBy([{
          ...action.newLocationBoard,
          new: true, // signal in UI to show newly added location
        }].concat(state.locationBoards), 'location.id'),
      };
    case types.ADD_LOCATION_BOARD_ERROR:
      return {
        ...state,
        isFetchingLocationBoards: false,
      };

    case types.UPDATE_LOCATION_BOARD_REQUESTED:
      return {
        ...state,
        isFetchingLocationBoards: true,
      };
    case types.UPDATE_LOCATION_BOARD_SUCCESS:
      return {
        ...state,
        isFetchingLocationBoards: false,
        locationBoards: state.locationBoards.map((f) => (f.id === action.updatedLocationBoard.id
          ? action.updatedLocationBoard
          : f)),
        locationBoardsByLocationMap: groupBy(state.locationBoards.map((f) => (f.id === action.updatedLocationBoard.id
          ? action.updatedLocationBoard
          : f)), 'location.id'),
      };
    case types.UPDATE_LOCATION_BOARD_ERROR:
      return {
        ...state,
        isFetchingLocationBoards: false,
      };

    case types.DELETE_LOCATION_BOARD_REQUESTED:
      return {
        ...state,
        isFetchingLocationBoards: true,
      };
    case types.DELETE_LOCATION_BOARD_SUCCESS:
      return {
        ...state,
        isFetchingLocationBoards: false,
        locationBoards: state.locationBoards.filter((f) => f.id !== action.deletedLocationBoardId),
        locationBoardsByLocationMap: groupBy(state.locationBoards.filter((f) => f.id !== action.deletedLocationBoardId), 'location.id'),
      };
    case types.DELETE_LOCATION_BOARD_ERROR:
      return {
        ...state,
        isFetchingLocationBoards: false,
      };
    default:
      return state;
  }
};
