import { combineReducers } from 'redux';
import tenant from '@pingum/app-state-management/tenant/reducer';
import log from '@pingum/app-state-management/log/reducer';
import error from '@pingum/app-state-management/error/reducer';
import success from '@pingum/app-state-management/success/reducer';
import status from '@pingum/app-state-management/status/reducer';
import tagType from '@pingum/app-state-management/tagType/reducer';
import tag from '@pingum/app-state-management/tag/reducer';
import dashboard from '@pingum/app-state-management/dashboard/reducer';
import event from '@pingum/app-state-management/event/reducer';
import comment from '@pingum/app-state-management/comment/reducer';
import eventStatus from '@pingum/app-state-management/eventStatus/reducer';
import eventData from '@pingum/app-state-management/eventData/reducer';
import eventTemplate from '@pingum/app-state-management/eventTemplate/reducer';
import supportRequest from '@pingum/app-state-management/supportRequest/reducer';
import apiKey from '@pingum/app-state-management/apiKey/reducer';
import eventType from '@pingum/app-state-management/eventType/reducer';
import userSubscription from '@pingum/app-state-management/userSubscription/reducer';
import eventSubscription from '@pingum/app-state-management/eventSubscription/reducer';
import eventNotification from '@pingum/app-state-management/eventNotification/reducer';
import userNotification from '@pingum/app-state-management/userNotification/reducer';
import userEvents from '@pingum/app-state-management/userEvents/reducer';
import eventUpload from '@pingum/app-state-management/eventUpload/reducer';
import collection from '@pingum/app-state-management/collection/reducer';
import condition from '@pingum/app-state-management/condition/reducer';
import form from '@pingum/app-state-management/form/reducer';
import pingumApp from '@pingum/app-state-management/app/reducer';
import app from './app/reducer';
import user from './user/reducer';
import feed from './feed/reducer';
import barn from './barn/reducer';
import horse from './horse/reducer';
import location from './location/reducer';
import locationBoard from './locationBoard/reducer';
import schedule from './schedule/reducer';
import request from './requests/reducer';
import board from './board/reducer';
import billables from './billables/reducer';
import invoice from './invoice/reducer';
import horseAccess from './horseAccess/reducer';
import search from './search/reducer';
import stream from './stream/reducer';
import eventTemplateReminder from './eventTemplateReminder/reducer';
import tagReminder from './tagReminder/reducer';
import horsePublicShare from './horsePublicShare/reducer'
import invoiceHeader from './invoiceHeader/reducer'

export default combineReducers({
  tenant,
  user,
  log,
  error,
  success,
  status,
  tagType,
  tag,
  dashboard,
  event,
  comment,
  eventStatus,
  eventData,
  eventTemplate,
  supportRequest,
  apiKey,
  eventType,
  userSubscription,
  eventSubscription,
  eventNotification,
  userNotification,
  userEvents,
  eventUpload,
  collection,
  condition,
  form,
  pingumApp,
  app,
  feed,
  barn,
  horse,
  location,
  locationBoard,
  schedule,
  board,
  billables,
  request,
  horseAccess,
  search,
  stream,
  eventTemplateReminder,
  tagReminder,
  invoice,
  horsePublicShare,
  invoiceHeader,
});
