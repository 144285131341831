// Location Board
export const GET_LOCATION_BOARDS_REQUESTED = 'GET_LOCATION_BOARDS_REQUESTED';
export const GET_LOCATION_BOARDS_SUCCESS = 'GET_LOCATION_BOARDS_SUCCESS';
export const GET_LOCATION_BOARDS_ERROR = 'GET_LOCATION_BOARDS_ERROR';
export const UPDATE_LOCATION_BOARD_REQUESTED = 'UPDATE_LOCATION_BOARD_REQUESTED';
export const UPDATE_LOCATION_BOARD_SUCCESS = 'UPDATE_LOCATION_BOARD_SUCCESS';
export const UPDATE_LOCATION_BOARD_ERROR = 'UPDATE_LOCATION_BOARD_ERROR';
export const ADD_LOCATION_BOARD_REQUESTED = 'ADD_LOCATION_BOARD_REQUESTED';
export const ADD_LOCATION_BOARD_SUCCESS = 'ADD_LOCATION_BOARD_SUCCESS';
export const ADD_LOCATION_BOARD_ERROR = 'ADD_LOCATION_BOARD_ERROR';
export const DELETE_LOCATION_BOARD_REQUESTED = 'DELETE_LOCATION_BOARD_REQUESTED';
export const DELETE_LOCATION_BOARD_SUCCESS = 'DELETE_LOCATION_BOARD_SUCCESS';
export const DELETE_LOCATION_BOARD_ERROR = 'DELETE_LOCATION_BOARD_ERROR';
export const SET_SELECTED_LOCATION_BOARD = 'SET_SELECTED_LOCATION_BOARD';
