import React from 'react';
import Chip from '@mui/material/Chip';
import { ExpandCircleDown } from '@mui/icons-material';

export const IMPORTANCE = ['Alert', 'Moderate', 'Success', 'Inform'];
export const IMPORTANCE_TYPES = {
  Alert: 'Alert',
  Moderate: 'Moderate',
  Success: 'Success',
  Inform: 'Inform',
};

export const getColorHexForImportance = (importance, theme) => {
  if (importance === 'Inform') return theme.palette.info.main;
  if (importance === 'Success') return theme.palette.success.main;
  if (importance === 'Moderate') return theme.palette.warning.main;
  if (importance === 'Alert') return theme.palette.error.main;
  return theme.palette.info.main;
};

export const getColorForImportance = (importance) => {
  if (importance === 'Inform') return 'info';
  if (importance === 'Success') return 'success';
  if (importance === 'Moderate') return 'warning';
  if (importance === 'Alert') return 'error';
  return 'info';
};

export const NOTE_TYPE = [{
  enum: 'Billing',
  label: 'Billing',
}, {
  enum: 'Competition',
  label: 'Show',
}, {
  enum: 'FeedChange',
  label: 'Feed Change',
}, {
  enum: 'LocationChange',
  label: 'Location Change',
}, {
  enum: 'Medical',
  label: 'Medical',
}, {
  enum: 'Note',
  label: 'Note',
}, {
  enum: 'ProfileChange',
  label: 'Profile Change',
}, {
  enum: 'TackEquipment',
  label: 'Tack / Equipment',
}];

export const PAYMENT_STATUSES = [{
  enum: 'Open',
  label: 'Open',
  chip: <Chip label="Open" color="primary" size="small" variant="outlined" />,
}, {
  enum: 'Paid',
  label: 'Paid',
  chip: <Chip label="Paid" color="success" size="small" variant="outlined" />,
}, {
  enum: 'Void',
  label: 'Void',
  chip: <Chip label="Void" color="secondary" size="small" variant="outlined" />,
}, {
  enum: 'Expense',
  label: 'Expense',
  chip: <Chip label="Expense" size="small" variant="outlined" />,
}]

export const INVOICE_STATUSES = [{
  enum: 'Draft',
  label: 'Draft',
  chip: <Chip label="Draft" color="error" variant="outlined" />,
  getChipContent: (replacementText, onDelete) => (<Chip label={replacementText || 'Draft'} color="error" variant="outlined" onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Open',
  label: 'Open',
  chip: <Chip label="Open" color="primary" variant="outlined" />,
  getChipContent: (replacementText, onDelete) => (<Chip label={replacementText || 'Open'} color="primary" variant="outlined" onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Paid',
  label: 'Paid',
  chip: <Chip label="Paid" color="success" variant="outlined" />,
  getChipContent: (replacementText, onDelete) => (<Chip label={replacementText || 'Paid'} color="success" variant="outlined" onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Void',
  label: 'Void',
  chip: <Chip label="Void" color="secondary" variant="outlined" />,
  getChipContent: (replacementText, onDelete) => (<Chip label={replacementText || 'Void'} color="secondary" variant="outlined" onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Uncollectable',
  label: 'Uncollectable',
  chip: <Chip label="Uncollectible" />,
  getChipContent: (replacementText, onDelete) => (<Chip label={replacementText || 'Uncollectible'} onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Pending',
  label: 'Payment Pending',
  chip: <Chip label="Payment Pending" color="primary" variant="outlined" />,
  getChipContent: (replacementText, onDelete) => (<Chip color="primary" variant="outlined" label={replacementText || 'Payment Pending'} onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}, {
  enum: 'Failed',
  label: 'Payment Failed',
  chip: <Chip label="Payment Failed" color="error" />,
  getChipContent: (replacementText, onDelete) => (<Chip color="error" label={replacementText || 'Payment Failed'} onDelete={onDelete && ((e) => { e.stopPropagation(); onDelete(e); })} deleteIcon={<ExpandCircleDown />} />),
}]

export const HORSE_ACCESS_TYPE = [{
  enum: 'Client',
  label: 'Client',
},
{
  enum: 'Veterinarian',
  label: 'Veterinarian',
}, {
  enum: 'Farrier',
  label: 'Farrier',
},
];

export const getNoteTypeDisplayName = (type) => {
  if (type === 'Note') return 'Note';
  if (type === 'Medical') return 'Health';
  if (type === 'FeedChange') return 'Feed Change';
  if (type === 'LocationChange') return 'Location Change';
  if (type === 'ProfileChange') return 'Profile Change';
  if (type === 'Competition') return 'Show';
  if (type === 'Billing') return 'Billing';
  return type;
};

export const SEARCH_TYPE = {
  Horse: 'Horse',
  User: 'User',
  Feed: 'Feed',
  Note: 'Note',
  Location: 'Location',
};
export const ACCESS_TYPE = {
  Client: 'Client',
  Veterinarian: 'Veterinarian',
  Farrier: 'Farrier',
};

export const getLocationTypeDisplayName = (location) => {
  if (location.type === 'Competition') return 'Show';
  return location.type
};
