/* eslint-disable indent */

// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

const numberOfDaysBetweenDates = (d1, d2) => {
  const diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

export default class ScheduleService {
  static searchSchedules(request, page, size, sort) {
    if (numberOfDaysBetweenDates(new Date(request.startDate), new Date(request.endDate)) > 100) {
      return Promise.reject(new Error('Cannot get schedules and calendar events/tasks for more than a 100 day range.'));
    }
    return this.httpService.post(`/search?page=${page}&size=${size}${sort}`, request);
  }

  static getSchedules(startTime, endTime, tags, page, size, sort) {
    if (numberOfDaysBetweenDates(new Date(startTime), new Date(endTime)) > 100) {
      return Promise.reject(new Error('Cannot get schedules and calendar events/tasks for more than a 100 day range.'));
    }
    return this.httpService.get(`?page=${page}&size=${size}${sort}&startTime=${startTime || new Date(10000)}&endTime=${endTime || new Date()}&tags=${tags || []}`);
  }

  static getChildrenSchedules(parentScheduleId, startTime, endTime, page, size, sort) {
    if (!parentScheduleId) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }
    if (numberOfDaysBetweenDates(new Date(startTime), new Date(endTime)) > 100) {
      return Promise.reject(new Error('Cannot get schedules and calendar events/tasks for more than a 100 day time range.'));
    }
    return this.httpService.get(`/${parentScheduleId}/children?page=${page}&size=${size}${sort}&startTime=${startTime || new Date(10000)}&endTime=${endTime || new Date()}`);
  }

  static getSchedule(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }
    return this.httpService.get(`/${id}`);
  }

  static updateSchedule(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteSchedule(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static addScheduleException(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/scheduleExceptions`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getSchedulelExceptionsForSchedule(id, startTime, endTime, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.get(`/${id}/scheduleExceptions?page=${page}&size=${size}${sort}&startTime=${startTime || new Date(10000)}&endTime=${endTime || new Date()}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /*
    Schedule Responses
  */

  static getScheduleResponses(schedulePubilcId, occurrenceDate, responderId) {
    if (!schedulePubilcId) {
      return Promise.reject(new Error('Missing Schedule Public ID'));
    }
    return this.httpService.get(`/${schedulePubilcId}/responses?occurrenceDate=${occurrenceDate === null
      ? ''
      : occurrenceDate.toISOString()}${responderId
        ? `&responderId=${responderId}`
        : ''}`);
  }

  static addScheduleResponses(schedulePubilcId, request) {
    if (!schedulePubilcId) {
      return Promise.reject(new Error('Missing Schedule Public ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${schedulePubilcId}/responses`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static sendScheduleResponseRequest(schedulePubilcId, request) {
    if (!schedulePubilcId) {
      return Promise.reject(new Error('Missing Schedule Public ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${schedulePubilcId}/responses`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static sendScheduleInviteRequest(schedulePubilcId, responderId) {
    if (!schedulePubilcId) {
      return Promise.reject(new Error('Missing Schedule Public ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${schedulePubilcId}/responses/${responderId}/requests`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }
}

ScheduleService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_EVENT_API
    : ''}/schedules`,
});
