import * as React from 'react';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';

import { TenantService } from '@pingum/api-services';

import { getTenantFromUrl } from '../../utils/urlHelper';

import AppBar from './appBar';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

import NoTenantCard from '../../components/UserStatusAndDisplayCards/NoTenantCard';
import LimitedAccessCard from '../../components/UserStatusAndDisplayCards/LimitedAccessCard';
import LimitedPaidClientAccessCard from '../../components/UserStatusAndDisplayCards/LimitedPaidClientAccessCard';
import OrganizationDeactivatedCard from '../../components/UserStatusAndDisplayCards/OrganizationDeactivatedCard';
import AccessDeniedCard from '../../components/UserStatusAndDisplayCards/AccessDeniedCard';
import PleaseLogInCard from '../../components/UserStatusAndDisplayCards/PleaseLogInCard';
import ErrorLoggingInCard from '../../components/UserStatusAndDisplayCards/ErrorLoggingInCard';

export const history = createBrowserHistory();

const isUserInTenant = (userTenants, tenant) => userTenants.indexOf(tenant) > -1;
// TODO fix this for domain specific
const tenantUrl = TenantService.getTenantUrl(getTenantFromUrl()).replace('pingum', 'questri').replace('questri.io', 'questri.app');

// TODO move this to a styles.js and classes format
const defaultContainerStyle = {
  backgroundColor: 'rgb(244, 244, 244)',
  width: '100vw',
  minHeight: '100vh',
};
const getContentToRender = (isLoading, loadingTimeout, auth0Error, isAuthenticated, accessDenied, deactivatedTenant, children) => {
  if (isLoading) {
    return <LoadingPlaceholder />;
  }
  if ((window || { location: { pathname: '' } }).location.pathname.indexOf('logout') >= 0) {
    return children; // return logout page if requested
  }
  if ((window || { location: { pathname: '' } }).location.pathname.indexOf('schedules') >= 0) {
    return children; // return schedule page
  }
  if ((window || { location: { pathname: '' } }).location.pathname.indexOf('invoices') >= 0) {
    return children; // return schedule page
  }
  if ((window || { location: { pathname: '' } }).location.pathname.indexOf('public/horses') >= 0) {
    return children; // return public horse page
  }
  if (auth0Error) {
    const queryString = window.location.search;
    const query = {};
    const pairs = (queryString[0] === '?'
      ? queryString.substr(1)
      : queryString).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return (
      <ErrorLoggingInCard query={query} />
    );
  }
  if (!isAuthenticated) {
    return (
      <PleaseLogInCard />
    );
  }
  if (accessDenied && !loadingTimeout) {
    return (
      <LoadingPlaceholder />
    );
  }
  if (accessDenied && loadingTimeout) {
    return (
      <AccessDeniedCard tenantUrl={tenantUrl} />
    );
  }
  if (deactivatedTenant) {
    return (
      <OrganizationDeactivatedCard tenantUrl={tenantUrl} />
    );
  }

  // Success! All checks pass so show content.
  return children;
};

const AppContainerComponent = ({
  children,
  isLoading,
  navigate,
}) => {
  const tenants = useSelector((state) => state.user.tenants);
  const isClient = useSelector((state) => state.user.isClient);
  const paidClientAccess = useSelector((state) => state.user.paidClientAccess);
  const selectedBarn = useSelector((state) => state.barn.selectedBarn);
  const tenantObject = useSelector((state) => state.tenant.tenant);

  const [loadingTimeout, setLoadingTimeout] = React.useState(false);

  React.useEffect(() => {
    // it takes a little more time for access to load so put a 5 second timeout in place to account for that
    if (!loadingTimeout) {
      setTimeout(() => {
        setLoadingTimeout(true)
      }, [5000])
    }
  }, [])

  // Auth0 values
  const {
    user,
    isAuthenticated,
    error: auth0Error,
  } = useAuth0();

  // Tenant information
  const tenant = getTenantFromUrl();
  const accessDenied = isAuthenticated && tenant && !isUserInTenant(tenants.map((t) => t.tenant), tenant);
  const deactivatedTenant = isAuthenticated && tenant && (tenantObject
    ? tenantObject.deactivated
    : false);

  /*
    Methods
  */

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar navigate={navigate} isLoading={isLoading} tenantObject={tenantObject} tenant={tenant} />
      <Box
        component="main"
        style={defaultContainerStyle}
        sx={{
          flexGrow: 1,
          p: {
            xs: '56px 0 3rem',
            sm: '48px 0 3rem',
          },
        }}
      >
        {getContentToRender(isLoading, loadingTimeout, auth0Error, isAuthenticated, accessDenied, deactivatedTenant, children)}

        {/* Authenticated user not on a tenant URL */}
        {(!accessDenied && !deactivatedTenant && (!tenant || tenant === 'questri') && isAuthenticated && !isLoading) && (
          <NoTenantCard barns={tenants} authUser={user} />
        )}
        {/* User is non staff role and has no horse access to a horse with PAID CLIENT Experience enabled */}
        {(!accessDenied && !deactivatedTenant && !!tenant && tenant !== 'questri' && isClient && !paidClientAccess && isAuthenticated && !isLoading) && (
          <LimitedAccessCard selectedBarn={selectedBarn || {}} />
        )}
        {/* User is non staff role and but has PAID CLIENT Experience enabled */}
        {(!accessDenied && !deactivatedTenant && !!tenant && tenant !== 'questri' && isClient && paidClientAccess && isAuthenticated && !isLoading) && (
          <LimitedPaidClientAccessCard selectedBarn={selectedBarn || {}} />
        )}
      </Box>
    </Box>
  );
};

AppContainerComponent.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
};

AppContainerComponent.defaultProps = {};

export default AppContainerComponent;
