// import { LocationService } from '@pingum/api-services';
import LocationService from '../../services/LocationService';
import LocationBoardService from '../../services/LocationBoardService';

import * as types from './types';
import {
  dispatchNetworkRequest,
  dispatchNetworkRequestDynamicSuccessDispatch,
} from '../networkDispatcher';

export const setSelectedLocationBoard = (locationBoard) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_LOCATION_BOARD,
    selectedLocationBoard: locationBoard,
  })
};

export const getLocationBoards = () => (dispatch) => {
  const promise = LocationBoardService.getLocationBoardsForBarn();
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_LOCATION_BOARDS_REQUESTED,
    successType: types.GET_LOCATION_BOARDS_SUCCESS,
    errorType: types.GET_LOCATION_BOARDS_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading location boards for this organization',
    responseName: 'locationBoards',
  });
  return promise;
};

export const addLocationBoard = (locationId, request) => (dispatch) => {
  const promise = LocationService.addLocationBoard(locationId, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.ADD_LOCATION_BOARD_REQUESTED,
    successType: types.ADD_LOCATION_BOARD_SUCCESS,
    errorType: types.ADD_LOCATION_BOARD_ERROR,
    promise,
    successMessage: 'Successfully created the location board',
    defaultErrorMessage: 'There was a problem adding the board to this location',
    responseName: 'newLocationBoard',
  });
  return promise;
};

export const updateLocationBoard = (id, request) => (dispatch) => {
  const promise = LocationBoardService.updateLocationBoard(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.UPDATE_LOCATION_BOARD_REQUESTED,
    successType: types.UPDATE_LOCATION_BOARD_SUCCESS,
    errorType: types.UPDATE_LOCATION_BOARD_ERROR,
    promise,
    successMessage: 'Successfully updated the location board',
    defaultErrorMessage: 'There was a problem updating the location',
    responseName: 'updatedLocationBoard',
  });
  return promise;
};

export const deleteLocationBoard = (id) => (dispatch) => {
  const promise = LocationBoardService.deleteLocationBoard(id);
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.DELETE_LOCATION_BOARD_REQUESTED,
    successDispatch: {
      type: types.DELETE_LOCATION_BOARD_SUCCESS,
      deletedLocationBoardId: id,
    },
    errorType: types.DELETE_LOCATION_BOARD_ERROR,
    promise,
    successMessage: 'Successfully deleted the location',
    defaultErrorMessage: 'There was a problem deleting the location',
    responseName: 'response',
  });
  return promise;
};
