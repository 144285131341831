import * as React from 'react';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  styled,
  alpha,
} from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import {
  Badge,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Icon,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  Divider,
  Link,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { showNotifications } from '@pingum/app-state-management/app/actions';

import {
  AccountCircle,
  AdminPanelSettings,
  Business,
  EditNotifications,
  Logout,
} from '@mui/icons-material';
import moment from 'moment';
import { getBarnNotices } from '../../redux/barn/actions';
import { searchQuestri } from '../../redux/search/actions';

import { AppBar } from './styles';
import appTheme from '../../utils/theme';
import useDebounce from '../../utils/useDebounce';
import NotificationList from '../../components/NotificationList';
import SearchList from '../../components/SearchList';
import QuestriLogo from '../../components/QuestriLogo';
import User from '../../components/User';
import { daysRemaining } from '../../utils/dateHelper';

export const history = createBrowserHistory();

const getDomainWithoutSubdomain = (url) => {
  const urlParts = new URL(url).hostname.split('.')

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4
      ? 3
      : 2))
    .join('.')
}

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0.5),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  fontSize: '.8rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': { width: '20ch' },
    },
  },
}));

const AppBarComponent = ({
  isLoading,
  tenantObject,
  navigate,
}) => {
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(appTheme.breakpoints.down('md'));

  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search, 1000);
  const sortModel = [];

  React.useEffect(() => {
    if (debouncedSearch) {
      dispatch(searchQuestri(debouncedSearch, 0, 25, sortModel.map((s) => `&sort=${s.field},${s.sort}`)));
    }
  }, [debouncedSearch]);

  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const selectedBarn = useSelector((state) => state.barn.selectedBarn);
  const barnNotices = useSelector((state) => state.barn.barnNotices);
  const permissions = useSelector((state) => state.user.permissions);
  const barnUser = useSelector((state) => state.user.barnUser);
  const isFetchingUser = useSelector((state) => state.user.isFetchingUser);
  const selectedLocation = useSelector((state) => state.location.selectedLocation);
  const unviewedNotificationCount = useSelector((state) => state.userNotification.unviewedNotificationCount);
  const myTimezone = useSelector((state) => state.app.myTimezone);
  const contextTimezone = useSelector((state) => state.app.contextTimezone);

  const pages = [
    {
      title: 'Board',
      path: '/board',
    },
    {
      title: 'Tasks',
      path: '/tasks/planning',
    },
    {
      title: 'Horses',
      path: '/horses',
      badgeCount: barnNotices.missingLocations.length + barnNotices.missingFeed.length + barnNotices.horseLodgingRequests.length,
    },
    {
      title: 'Horse Billing',
      path: '/billing/dashboard',
      badgeCount: barnNotices.openHorseBillables && barnNotices.openHorseBillables > 0 && 1,
    },
    {
      title: 'Settings',
      path: '/settings/feeds',
    },
  ];

  let settings = [{
    name: 'Profile',
    path: '/settings/profile',
    icon: <AccountCircle color="secondary" />,
  }, {
    name: 'My Notification Settings',
    path: '/settings/notifications',
    icon: <EditNotifications color="secondary" />,
  }, {
    name: 'My Organizations',
    path: null,
    href: `${window.location.protocol}//${getDomainWithoutSubdomain(window.location.href)}`,
    divider: true,
    icon: <Business color="secondary" />,
  }];
  if (permissions.owner) {
    settings = [...settings, {
      name: 'Admin Settings & Billing',
      path: '/settings/admin',
      divider: true,
      icon: <AdminPanelSettings color="secondary" />,
    }]
  }
  settings = [...settings, {
    name: 'Logout',
    path: '/logout',
    icon: <Logout color="secondary" />,
  }]

  const isPageSelected = (pagePath) => {
    const windowPath = window.location.pathname;
    if (pagePath === '/board' && (windowPath.startsWith('/board'))) {
      return true;
    }
    if (pagePath === '/horses' && windowPath.startsWith('/horses')) {
      return true;
    }
    if (pagePath === '/tasks/planning' && (windowPath === '/' || windowPath.startsWith('/tasks'))) {
      return true;
    }
    if (pagePath === '/tasks' && windowPath.startsWith('/tasks')) {
      return true;
    }
    if ((pagePath === '/billing' || pagePath === '/billing/dashboard' || pagePath === '/billing/billables' || pagePath === '/billing/recurring') && windowPath.startsWith('/billing')) {
      return true;
    }
    if (pagePath === '/settings/feeds' && windowPath.startsWith('/settings')) {
      return true;
    }
    return false;
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notificationAnchorElement, setNotificationAnchorElement] = React.useState(null);
  const [searchAnchorElement, setSearchAnchorElement] = React.useState(null);

  React.useEffect(() => {
    if (selectedBarn && selectedBarn.id) {
      dispatch(getBarnNotices(selectedBarn.id));
    }
  }, [selectedBarn]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNotificationButtonClick = (e) => {
    setNotificationAnchorElement(e.target);
    dispatch(showNotifications());
  };

  const trialDaysRemaining = (tenantObject.freeTrialEndDate && new Date(tenantObject.freeTrialEndDate).getTime() > new Date().getTime())
    ? daysRemaining(tenantObject.freeTrialEndDate || 0)
    : null

  return (
    <AppBar position="fixed">
      <Container maxWidth="100%">
        <Toolbar disableGutters variant="dense">
          <Link href="/board">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
            >
              <QuestriLogo removePadding color="#fff" fontSize={30} />
            </Typography>
          </Link>

          {
            isAuthenticated
            && (

              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>

                {
                  anchorElNav && (
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: {
                          xs: 'block',
                          md: 'none',
                        },
                      }}
                    >
                      {(permissions.owner || permissions.admin || permissions.staff) && (
                        pages.map((page) => (
                          <MenuItem
                            key={page.title}
                            ref={page.ref}
                            onClick={() => {
                              handleCloseNavMenu();
                              setTimeout(() => {
                                if (page.onClick) {
                                  page.onClick()
                                } else {
                                  navigate(page.path);
                                }
                              }, 250)
                            }}
                          >
                            <Badge
                              badgeContent={page.badgeCount}
                              color="error"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <Typography
                                textAlign="center"
                                sx={isPageSelected(page.path)
                                  ? { fontWeight: 'bold' }
                                  : {}}
                              >{page.title}
                              </Typography>
                            </Badge>
                          </MenuItem>
                        ))
                      )}
                    </Menu>
                  )
                }

              </Box>
            )
          }

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: 'flex',
                md: 'none',
              },
            }}
          >
            <QuestriLogo removePadding color="#fff" />
          </Typography>

          {
            !isAuthenticated
              ? (
                <>
                  <Box sx={{ flex: 1 }} />
                  <Box sx={{ flexGrow: 0 }}>
                    <Button
                      color="secondary"
                      sx={{ mr: 2 }}
                      disabled={isLoading}
                      onClick={() => {
                        const returnPath = window.location.pathname.indexOf('logout') >= 0
                          ? '/'
                          : window.location.pathname
                        loginWithRedirect({ appState: { returnTo: returnPath + window.location.search } });
                      }}
                      variant="contained"
                    >
                      Log In
                    </Button>
                    <Button
                      color="secondary"
                      disabled={isLoading}
                      onClick={() => {
                        const returnPath = window.location.pathname.indexOf('logout') >= 0
                          ? '/'
                          : window.location.pathname
                        loginWithRedirect({
                          screen_hint: 'signup',
                          ui_locales: 'es',
                          mode: 'signUp',
                          appState: { returnTo: returnPath + window.location.search },
                        });
                      }}
                      variant="text"
                    >
                      Sign Up
                    </Button>
                  </Box>
                </>
              )
              : (
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                    }}
                  >
                    {
                      (permissions.owner || permissions.admin || permissions.staff)
                      && pages.map((page) => (
                        <Badge
                          key={page.title}
                          badgeContent={page.badgeCount}
                          variant="dot"
                          invisible={!page.badgeCount}
                          color="error"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          sx={{
                            '& .MuiBadge-badge': {
                              right: 5,
                              top: 2,
                            },
                          }}
                        >
                          <Button
                            ref={page.ref}
                            onClick={() => {
                              if (page.onClick) {
                                page.onClick()
                              } else {
                                navigate(page.path);
                                handleCloseNavMenu();
                              }
                            }}
                            sx={{
                              color: 'white',
                              display: 'block',
                              marginTop: 0,
                              marginBottom: 0,
                              marginX: '4px',
                              paddingY: 0,
                              ...(isPageSelected(page.path)
                                ? { fontWeight: 'bold' }
                                : {}),
                            }}
                            size="small"
                          >
                            {page.title}
                          </Button>
                        </Badge>
                      ))
                    }
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                    }}
                  >
                    {selectedLocation && moment.tz(contextTimezone).zoneAbbr() !== moment.tz(myTimezone).zoneAbbr() && (
                      <Tooltip
                        title={(
                          <>
                            <div>Your current timezone {moment.tz(myTimezone).zoneAbbr()} ({myTimezone}) is different
                              than this location <b>{moment.tz(selectedLocation.timezone).zoneAbbr()} ({selectedLocation.timezone})</b>.
                            </div>
                            <div>All calendar events are shown in {moment.tz(selectedLocation.timezone).zoneAbbr()} {selectedLocation.timezone}
                              timezone.
                            </div>
                          </>
                        )}
                      >
                        <IconButton color="warning">
                          <Icon>access_time</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    sx={{
                      marginRight: '1rem',
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                    }}
                  >
                    {
                      (
                        trialDaysRemaining !== null
                      )
                      && (
                        <Box
                          sx={{
                            marginRight: '.5rem',
                            display: {
                              xs: 'none',
                              md: 'flex',
                            },
                            alignItems: 'center',
                          }}
                        >
                          <Tooltip title={`Trial ends in ${trialDaysRemaining} days. In order to continue using Questri after the trial ends, the owner of the organization needs to purchase a subscription from the billing page.`} placement="right">
                            <Chip
                              size="small"
                              color={trialDaysRemaining > 3
                                ? 'info'
                                : 'warning'}
                              label={`Trial - ${trialDaysRemaining
                                ? `${trialDaysRemaining} days remaining`
                                : 'ending today'}`}
                              clickable={permissions.owner}
                              onClick={() => { if (permissions.owner) { window.location.href = '/settings/admin'; } }}
                            />
                          </Tooltip>
                        </Box>
                      )
                    }
                    {
                      (permissions.owner || permissions.admin || permissions.staff) && (
                        <Search>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                            value={search}
                            onChange={(e) => { setSearchAnchorElement(e.target); setSearch(e.target.value); }}
                            placeholder="Search..."
                            inputProps={{ 'aria-label': 'search' }}
                          />

                          <SearchList
                            searchText={search}
                            navigate={navigate}
                            debouncedSearchText={debouncedSearch}
                            handleSearchChange={(e) => setSearch(e.target.value)}
                            anchorElement={searchAnchorElement}
                            fullScreen={fullScreen}
                            hideSearchResults={() => {
                              setSearchAnchorElement(null);
                              setSearch('');
                            }}
                          />
                        </Search>
                      )
                    }
                  </Box>
                  <Box
                    sx={{ display: { md: 'none' } }}
                  >
                    {selectedLocation && moment.tz(contextTimezone).zoneAbbr() !== moment.tz(myTimezone).zoneAbbr() && (
                      <Tooltip
                        title={(
                          <>
                            <div>Your current timezone {moment.tz(myTimezone).zoneAbbr()} ({myTimezone}) is different
                              than this location <b>{moment.tz(selectedLocation.timezone).zoneAbbr()} ({selectedLocation.timezone})</b>.
                            </div>
                            <div>All calendar events are shown in {moment.tz(selectedLocation.timezone).zoneAbbr()} {selectedLocation.timezone}
                              timezone.
                            </div>
                          </>
                        )}
                      >
                        <IconButton color="warning">
                          <Icon>access_time</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 0,
                      mr: 1.5,
                    }}
                  >
                    <IconButton
                      style={{ color: 'white' }}
                      onClick={handleNotificationButtonClick}
                    >
                      <Badge
                        badgeContent={unviewedNotificationCount}
                        color="error"
                        invisible={!unviewedNotificationCount}
                      >
                        <Icon>
                          notifications
                        </Icon>
                      </Badge>
                    </IconButton>
                    <NotificationList
                      anchorElement={notificationAnchorElement}
                      fullScreen={fullScreen}
                      onClose={() => setNotificationAnchorElement(null)}
                    />
                  </Box>
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Profile options">
                      <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0 }}
                      >
                        {(!isFetchingUser && barnUser) && <User mini user={barnUser} cacheBust />}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <Box key={`settings-link-${setting.name}`}>
                          <MenuItem
                            onClick={() => {
                              if (setting.path) {
                                handleCloseUserMenu();
                                setTimeout(() => {
                                  navigate(setting.path)
                                }, 200);
                              } else {
                                window.location.href = setting.href;
                              }
                            }}
                          >
                            {setting.icon}
                            <Typography textAlign="center" sx={{ ml: 1 }}>{setting.name}</Typography>
                          </MenuItem>
                          {setting.divider && <Divider sx={{ m: 1 }} />}
                        </Box>
                      ))}
                    </Menu>
                  </Box>
                </>
              )
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};

AppBarComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tenantObject: PropTypes.object,
  navigate: PropTypes.func.isRequired,
};

AppBarComponent.defaultProps = { tenantObject: {} };

export default AppBarComponent;
