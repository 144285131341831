import HttpService from '@pingum/api-services/HttpService';

export default class LocationBoardService {
  static getLocationBoardsForBarn() {
    return this.httpService.get('');
  }

  static deleteLocationBoard(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Location Board ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateLocationBoard(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Location Board ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }
}

LocationBoardService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/locationBoards`,
});

